@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #c7000d;
  /* --font-family: -apple-system, BlinkMacSystemFont, 'Microsoft Yahei', Helvetica; */
  --font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --container-px: 10px;
  --section-width: 1280px;

  --tw-border-opacity: 1;
  --tw-content: "";
}

@media (min-width: 768px) {
  :root {
    --container-px: 16px;
  }
}

@media (min-width: 1024px) {
  :root {
    --container-px: 32px;
  }
}

@layer base {

  html,
  body {
    padding: 0;
    margin: 0;
  }

  article,
  footer,
  header,
  main,
  nav,
  section {
    display: block;
  }

  html {
    font-size: 100px;
    -webkit-text-size-adjust: none;
    -webkit-touch-callout: none;
    -webkit-overflow-scrolling: touch;
    appearance: none;
    -webkit-appearance: none;
  }

  body {
    color: black;
    font-size: .16rem;
    line-height: 1.25;
    font-weight: 200;
    font-family: var(--font-family);
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  img {
    border-width: 0;
  }

  input[type=button],
  button {
    padding: 0;
    border-width: 0;
    background-color: transparent;
    cursor: pointer;
  }


  select,
  input,
  textarea {
    outline: none;
    margin: 0;
    padding: 0;
    border-width: 0;
    outline: none;
    background-color: transparent;
  }

  input,
  button {
    font-weight: 200;
    font-family: var(--font-family);
    font-size: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 200;
  }

  p {
    margin: 0;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: rgb(241 242 244 / var(--tw-border-opacity));
  }
  
}

@layer components {
  .section-wrap {
    @apply max-w-full px-[var(--container-px)] box-border mx-auto w-[var(--section-width)];
  }

  .rich-text * {
    @apply max-w-full;
  }
  .rich-text img {
    @apply !h-auto;
  }

  /* .menu-btn {
    @apply relative flex justify-center items-center border-white border-t-2 border-b-2
           before:w-full before:h-2 before:bg-white before:block before:transition-transform
           after:w-full after:h-2 after:bg-white after:hidden after:transition-transform;
  }
  .menu-btn.open {
    @apply border-t-0 border-b-0 after:block after:absolute after:w-2/3 after:rotate-45 before:absolute before:w-2/3 before:-rotate-45;
  } */
  .menu-btn {
    @apply relative flex justify-center items-center flex-col
           before:absolute before:w-full before:h-2 before:bg-white before:transition-transform before:top-0
           after:absolute after:w-full after:h-2 after:bg-white after:transition-transform after:bottom-0
           child-[i]:h-2 child-[i]:bg-white child-[i]:w-full;
  }
  .menu-btn.open {
    @apply child-[i]:hidden before:top-auto before:w-4/5 before:rotate-45 after:bottom-auto after:w-4/5 after:-rotate-45;
  }

  .animate-delay-200.animate__animated {
    animation-delay: .4s;
  }

  .hero-mask {
    background-color: rgba(26, 26, 26, .26);
    background-image: linear-gradient(to bottom, #1b1c1e, rgba(0, 30, 32, .2) 16%, rgba(81, 126, 118, .1));
  }

  .animation-blockFadeIn {
    animation-name: blockFadeInUp;
  }
  
  @keyframes blockFadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 40%, 0);
      transform: translate3d(0, 40%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
}

body {
  min-height: 100vh;
}